import React from 'react'; // Add this import
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client"; // Import createRoot
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.min.css";

import Router from "./router";
import i18n from "./translation";

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement); // Use createRoot instead of ReactDOM.render
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
